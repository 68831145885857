<template>
  <div>
    <Header/>

    <div class="container success">
      <h2>Obrigado. Sua avaliação foi enviada com sucesso!</h2>
    </div>
  </div>
</template>

<script>

import Header from '../components/Header'

export default {
  name: 'Success',

  components:{
    Header
  },
}
</script>

<style scoped>
  .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  h2{
    text-align: center;
  }
</style>
